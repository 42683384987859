import React from 'react'

import SEO from '../components/seo'
import Main from '../layouts/main'
import { BigHeader } from '../molecules/BigHeader/BigHeader'
import { OnlyText } from '../organisms/HeaderAndText/OnlyText'
import { Colors } from '../styles/colors'

const ONasPage = () => (
  <Main>
    <SEO title='Poradnia Rehabilitacyjna Azory' />
    <BigHeader headerText='O nas' backgroundColor={Colors.white} headerColor={Colors.secondary} />
    <OnlyText
      backgroundColor={Colors.white}
      contentText={
        <>
          Nasza placówka działa od 2001 roku. Od zawsze naszym priorytetem była terapia najbardziej dopasowana do
          pacjenta. Nasze leczenie opiera się na kompleksowej opiece pacjenta w zakresie rehabilitacji. Diagnoza
          lekarska specjalisty rehabilitacji lek. med. Jarosława Bal oraz skuteczne metody fizjoterapeutyczne stosowane
          przez zespół terapeutów oparte są na fachowej wiedzy, doświadczeniu i zaangażowaniu w wykonywaną pracę.
        </>
      }
    />
  </Main>
)

export default ONasPage
